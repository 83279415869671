<template>
  <div>
    <Header />
    <section class="section1">
      <div class="text-box">
        <div class="h3 mb-3">订单关怀</div>
        <div class="desc mb-2">
          订单关怀均可开启“多任务”模式，如可以区分不同的收货地区、区分不同的会员等级、区分不同的特征人群等创建不同的任务，真正做到订单关怀千人千面；

        </div>
        <div class="desc mb-2">
          提供优先级设置，多个提醒中如有重复人群，则发送优先级高的提醒，保证买家收到特征鲜明的提醒信息。
        </div>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;">
          <img style="width: 100%;"
               src="~@/assets/images/crm_order_01.png">
        </device-browser>
      </div>
    </section>
    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import DeviceBrowser from '@/components/device-browser'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'Order',
  components: {
    Header,
    Footer,
    DeviceBrowser,
    BannerRegistration
  }
}

</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  width: 100%;
  height: 450px;
  background: url("~@/assets/images/banner06.png");
  background-size: 100%;
  background-position: center;
  color: #fff;

  .text-box {
    position: absolute;
    left: 16%;
    top: 220px;

    .desc {
      width: 500px;
      color: #fff;
    }
  }
}
</style>
